import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h2" components={components}>{`Ha!`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`I post, therefore I am.`}</MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "path": "/hello-world",
  "date": "2018-11-13",
  "title": "first post",
  "image": "2018-11-16-hello-descartes.png",
  "sharpImage": true,
  "imgOverlay": null,
  "tags": ["cool philosophers"],
  "excerpt": "Who could be vain enough to want their own blog?",
  "published": false
};
      